import React from 'react';
import { Link } from 'react-router-dom';
import { SOURCECLEAR_COMPONENTS, veracodeStartAScanPage } from '~/constants/ModelConstants';
import veracodePlatformLogo from '~/images/veracodePlatformLogo.png';
import config from '~/config';
import RegistryLink from '~/components/RegistryLink';
import { Button } from 'fluorine-ui';
import ToggleSwitch from '~/components/toggle/ToggleSwitch';
import Helpers from '~/utils/Helpers';

export const VCTabsHeader = () => {
  return (
    <div className={'vcHeader'}>
      <div className={'vcSectionTitle flex flex--justify-content--space-between'}>
        <div>
          <h1>Software Composition Analysis</h1>
        </div>
        <div>
          <img src={veracodePlatformLogo} alt="Veracode" width="148px" />
        </div>
      </div>
      <div className="vcActions flex flex--justify-content--end mb--">
        <div
          className="external-link flex flex--align-items--center"
          data-automation-id="VCHeader-VulnerabilityDatabaseLink"
        >
          <RegistryLink
            href={`${config.VC_REGISTRY_URL}/${SOURCECLEAR_COMPONENTS.VULNERABILITY_DATABASE.slug}/search`}
          >
            {SOURCECLEAR_COMPONENTS.VULNERABILITY_DATABASE.name}
            <i className="glyphicon glyphicon-new-window" />
          </RegistryLink>
        </div>
        <div className="btn-group">
          <Link to={veracodeStartAScanPage}>
            <Button variant="primary">Start a Scan</Button>
          </Link>
        </div>
      </div>

      {Helpers.isScaPlatformUIEnabled() && (
        <div className="vcActions flex flex--justify-content--end mb--">
          <ToggleSwitch link="/sca-home" description="New SCA Home (Beta)" />
        </div>
      )}
    </div>
  );
};
