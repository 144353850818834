import React from 'react';
import VCIcon from '~/views/components/veracode/VCIcon';
import veracodeHomeLogo from '~/images/veracodeHomeLogo.png';
import { VCIconComponentIcon } from '~/types/VCIconComponentIcon';
import { useLocation } from 'react-router-dom';

interface VCNavIconProps {
  icon: VCIconComponentIcon;
}

function VCNavIcon(props: VCNavIconProps) {
  const {
    icon: { icon_name },
  } = props;
  const location = useLocation();
  const isScaPlatformUIOpen = location.pathname.startsWith('/sca-home');

    // Alternative JSX for 'home' icon when SCA Platform UI is open
    if (icon_name.includes('home') && isScaPlatformUIOpen) {
      return <img src={veracodeHomeLogo} alt="SCA Platform Home Icon" style={{ verticalAlign: 'bottom', width: '22.5px' }} />;
    }

  const { currentIcon = { icon_name: '', icon_type: '' }, currentIconClassNames = '' } = ((
    icon_name: string
  ): { [key: string]: any } => {
    switch (icon_name) {
      case 'home':
        return {
          currentIcon: { ...props.icon, icon_name: `${icon_name}-lg-alt` },
          currentIconClassNames: 'vcNavIcon',
        };

      case 'question-sign':
        return {
          currentIcon: { ...props.icon },
          currentIconClassNames: 'vcNavHelperCenterIcon',
        };
      case 'user':
        return {
          currentIcon: { ...props.icon },
          currentIconClassNames: 'vcNavUserIcon',
        };

      default:
        return {
          currentIcon: { ...props.icon },
          currentIconClassNames: 'vcNavIcon',
        };
    }
  })(icon_name);

  return <VCIcon baseClass={`navIcon ${currentIconClassNames}`} icon={currentIcon} />;
}

export default VCNavIcon;
