import React from 'react';
import veracodePlatformLogo from '~/images/veracodePlatformLogo.png';

export const VCStartScanHeader = () => {
  return (
    <div className={'vcHeader'}>
      <div
        className={'vcSectionTitle flex flex--justify-content--space-between vcStartScanPageHeader'}
      >
        <div>
          <h1>Start a Scan: Software Composition Analysis</h1>
        </div>
        <div>
          <img src={veracodePlatformLogo} alt="Veracode" width="148px" />
        </div>
      </div>
    </div>
  );
};
