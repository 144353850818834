import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { RouteComponentProps, Switch, withRouter } from 'react-router-dom';

import VCTabs from '~/views/components/veracode/VCTabs';
import vcFavicon from '~/favicon.ico';
import VCFooter from '~/views/containers/veracode/VCFooter';
import VCStartScanFooter from '~/components/VCStartScanFooter';
import LoaderWrapper from '~/components/LoaderWrapper';
import(/* webpackChunkName: "veracode" */ '../styles/veracode/styles.scss');
import(/* webpackChunkName: "glyphicons" */ '../styles/veracode/glyphicons.css');
import ErrorBoundary from '~/components/ErrorBoundary';
import * as MODEL from '~/constants/ModelConstants';
import CaptureRouteNotFound from '~/components/CaptureRouteNotFound';
import { renderRoutesInPath } from '~/utils/RouterUtils';
import routes from '~/routes';
import VCNav from '~/views/containers/veracode/VCNav';
import { VCTabsHeader } from '~/views/components/veracode/VCTabsHeader';
import { VCStartScanHeader } from '~/views/components/veracode/VCStartScanHeader';

interface VCPageProps extends RouteComponentProps {
  vcMyState?: object;
  organization: object;
  render?: (...args: any[]) => any;
  vcNavState?: object;
}

class VCPage extends Component<VCPageProps> {
  onCancelStartScan() {
    window.history.go(-1);
  }
  render() {
    const {
      history,
      vcNavState: { navigation },
      vcMyState: { permissions },
    } = this.props;
    const {
      location: { pathname },
    } = history;

    const { scaUpload } = permissions;
    const { platform: platformBaseUrl } = navigation;
    const scaUrl = platformBaseUrl + MODEL.veracodeScaUrl;
    const isVcStartScanPageOpen = pathname === '/start-scan';
    const isScaPlatformUIOpen = pathname.startsWith('/sca-home');
    // change background to dark mode when sca-platform-ui is open to prevent flashing
    if (isScaPlatformUIOpen && document.body.style.background !== '#202022') {
      document.body.style.background = '#202022';
    } else if (!isScaPlatformUIOpen && document.body.style.background !== 'inherit') {
      document.body.style.background = 'inherit';
    }
    const routerSwitchOutlet = (
      <CaptureRouteNotFound>
        <Switch children={renderRoutesInPath(routes)} />
      </CaptureRouteNotFound>
    );
    const vcPageContent = !scaUpload ? (
      <div className="container flex flex--content">
        <ErrorBoundary>{routerSwitchOutlet}</ErrorBoundary>
      </div>
    ) : (
      <VCTabs history={history} scaUrl={scaUrl}>
        <div id="upload-and-scan" label="Upload and Scan">
          <LoaderWrapper className="grid__item col-1-1 flex" isLoaderShowing={true}>
            {''}
          </LoaderWrapper>
        </div>
        <div id="agent-based-scan" label="Agent-Based Scan">
          <ErrorBoundary>{routerSwitchOutlet}</ErrorBoundary>
        </div>
      </VCTabs>
    );

    return (
      <Fragment>
        <Helmet titleTemplate="%s : Veracode Platform" defaultTitle="Veracode Platform">
          <link rel="icon" href={vcFavicon} sizes="32x32" />
          <title>Veracode Platform</title>
        </Helmet>
        <VCNav isScaPlatformUIOpen={isScaPlatformUIOpen} />

        {isScaPlatformUIOpen ? (
          <ErrorBoundary>{routerSwitchOutlet}</ErrorBoundary>
        ) : (
          <Fragment>
            {isVcStartScanPageOpen ? <VCStartScanHeader /> : <VCTabsHeader />}
            <div className="vcBody">
              {isVcStartScanPageOpen ? (
                <ErrorBoundary>{routerSwitchOutlet}</ErrorBoundary>
              ) : (
                vcPageContent
              )}
            </div>
          </Fragment>
        )}
        <VCPageFooter
          isVcStartScanPageOpen={isVcStartScanPageOpen}
          handleStartScanCancel={() => this.onCancelStartScan()}
          isScaPlatformUIOpen={isScaPlatformUIOpen}
        />
      </Fragment>
    );
  }
}

interface VCPageFooterProps {
  isVcStartScanPageOpen: boolean;
  handleStartScanCancel: () => void;
  isScaPlatformUIOpen: boolean;
}
const VCPageFooter = ({
  isVcStartScanPageOpen,
  handleStartScanCancel,
  isScaPlatformUIOpen,
}: VCPageFooterProps) =>
  isVcStartScanPageOpen ? (
    <VCStartScanFooter handleCancel={handleStartScanCancel} />
  ) : (
    <VCFooter isScaPlatformUIOpen={isScaPlatformUIOpen} />
  );

function mapStateToProps(state) {
  return { vcNavState: state.vcNavState, vcMyState: state.vcMyState };
}

export default withRouter(connect(mapStateToProps)(VCPage));
